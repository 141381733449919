<template>
  <div class="about">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-2">About the PFDAA</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <p class="org-description is-size-5">
        The (P.F.D.A.A.) Polish Folk Dance Association of the Americas, Inc.
        was organized in 1983 to strengthen the network of Polish dance companies in the Western world.
        The membership currently consists of 35 ensembles from the United States and Canada.
        The main goal of the PFDAA is to sponsor North American Polish folk dance festivals and serve as a central source for information regarding Polish folk music, dance, customs, and wardrobe.
        The success of this organization is evident in the many festivals and joint concerts
        that have been conducted in the USA and Canada.
        Our festivals are based on a combination of dance classes, social activities, and a gala concert.
      </p>
      <br />
      <div class="subtitle">Previous PFDAA Festivals</div>
      <div class="list">
        <div class="list-item" v-for="festival in festivals" :key="festival.id">{{festival.year}} - {{festival.name}}</div>
      </div>
      <p>
        For more information about the PFDAA or to inquire about having your Polish Folk dance ensemble join the association, please e-mail us at
        <a href="mailto:pfdaacontact@gmail.com">pfdaacontact@gmail.com</a>
      </p>
    </div>
  </div>
</template>
<script>

import FestivalService from "../services/festivalService";

export default {
  name: "About",
  data() {
    return {
      festivals: []
    };
  },
  async mounted(){
    this.festivals = await FestivalService.getAll()
  }
};
</script>
<style lang="scss" scoped>
.org-description {
  margin-top: 50px;
}
.hero-body {
  background: #ed213a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>